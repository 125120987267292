import React from "react"
import Layout from "../../components/layout"
import SEO from "../../components/seo"
import { Container, Grid } from "../../components/grid/grid"
import { BtnContainedPrimary } from "../../components/button/button"
import Stepbar from "../../components/stepbar/stepbar"
import ImageGiftopen from "../../images/regalo-abierto.gif"

export default class DownloadPage extends React.Component {
  
  state = { 
    whatisparquick: "",
    usefulwork: "",
    usefulleisure: "",
    notuseful: "",
    paytariff: "",
    usestimes: "0",
    understandsaving: "",
    newidea: "",
    newideaconcept: "",
    email: "",
  }

  handleInputChange = event => {
    const target = event.target
    const value = target.value
    const name = target.name

    this.setState({
      [name]: value,
    })
  }

  handleChange = selectedOption => {
    this.setState({ selectedOption });
    console.log(`Option selected:`, selectedOption);
  };
  
  render() {

    const usefulwork = this.state.usefulwork;
    const usefulleisure = this.state.usefulleisure;
    let notuseful;

    if((usefulwork === "No") || (usefulleisure === "No")){
      notuseful = <div class="margintop1-5">
      <p>¿Por qué no te es útil?</p>

      <div class="inputWrap">
        <input  type="radio" id="notuseful-iknowwhere" name="notuseful" value="Ya conozco las zonas donde aparcar" onChange={this.handleInputChange}/>
        <label for="notuseful-iknowwhere">Ya conozco las zonas donde aparcar.</label>
      </div>

      <div class="inputWrap">
        <input type="radio" id="notuseful-notaproblem" name="notuseful" value="No me supone problema buscar aparcamiento" onChange={this.handleInputChange}/>
        <label for="notuseful-notaproblem">No me supone problema buscar aparcamiento.</label>
      </div>

      <div class="inputWrap">
        <input type="radio" id="notuseful-app" name="notuseful" value="Ya utilizo una app alternativa para ello" onChange={this.handleInputChange}/>
        <label for="notuseful-app">Ya utilizo una app alternativa para ello.</label>
      </div>

      <div class="inputWrap">
        <input type="radio" id="notuseful-other" name="notuseful" value="Otra razón" onChange={this.handleInputChange}/>
        <label for="notuseful-other">Otra razón.</label>
      </div>
    </div>
    }



    const newidea = this.state.newidea;
    let newideaconcept;

    if(newidea === "Sí"){
      newideaconcept = <div class="margintop1-5">
      <p>¿Para qué otro tipo de servicios lo considerarías útil?</p>
      <input  type="text" name="newideaconcept" value={this.state.newideaconcept} onChange={this.handleInputChange}/>
    </div>
    }

    return (
      <Layout>
      <SEO 
      title="Sobre Parquick"
      />

        <Container>
          <Grid>
            <div class="l-start-3 l-end-11 m-start-2 m-end-6">

              <Stepbar 
                step2="¡Último paso!"
                step2Class="active"
              />
              <h1>Sobre Parquick:</h1>

              <form id="poll" 
              action="https://formspree.io/xjvynwdo"
              method="POST">
                <div>
                  <p>¿Entiendes qué servicio ofrece Parquick?</p>
                  <div class="inputWrap">
                    <input type="radio" id="whatisparquick-parkandpay" name="whatisparquick" value="App para aparcar y pagar el parking" onChange={this.handleInputChange}/>
                    <label for="whatisparquick-parkandpay">Aparcar y pagar el parking cubierto.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="whatisparquick-guide" name="whatisparquick" value="App para encontrar aparcamiento y ser guiado hasta él" onChange={this.handleInputChange}/>
                    <label for="whatisparquick-guide">Buscar parking cubierto y ser guiado hasta él.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="whatisparquick-book" name="whatisparquick" value="App para buscar parking y reservar" onChange={this.handleInputChange}/>
                    <label for="whatisparquick-book">Buscar parking cubierto y reservar una plaza con antelación.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="whatisparquick-parkandpay" name="whatisparquick" value="App para aparcar y pagar el parking" onChange={this.handleInputChange}/>
                    <label for="whatisparquick-parkandpay">Encontrar plaza libre en la calle.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="whatisparquick-dontknow" name="whatisparquick" value="No lo sé" onChange={this.handleInputChange}/>
                    <label for="whatisparquick-dontknow">No lo sé.</label>
                  </div>
                </div>

                <div class="margintop1-5">
                  <p>¿Qué características de Parquick son las más útiles para tu uso?</p>
                  <div class="inputWrap">
                    <input  class="checkbox" type="checkbox" id="feature-instantly" name="feature" value="Encontrar aparcamiento al momento"/>
                    <label for="feature-instantly">Encontrar aparcamiento al momento.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-spaces" name="feature" value="Disponibilidad de los parkings en tiempo real"/>
                    <label for="feature-spaces">Disponibilidad de los parkings en tiempo real.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-time" name="feature" value="Ganar más tiempo"/>
                    <label for="feature-time">Ganar más tiempo.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-pollution" name="feature" value="Reducir la contaminación generada"/>
                    <label for="feature-pollution">Reducir la contaminación generada.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-money" name="feature" value="Ahorrar dinero"/>
                    <label for="feature-money">Ahorrar dinero.</label>
                  </div>
              
                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-guide" name="feature" value="Ser guiado hasta el parking"/>
                    <label for="feature-guide">Ser guiado hasta el parking.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-spacenumber" name="feature" value="Guardar el número de plaza"/>
                    <label for="feature-spacenumber">Guardar el número de plaza.</label>
                  </div>

                  <div class="inputWrap">
                    <input class="checkbox" type="checkbox" id="feature-none" name="feature" value="Ninguna en concreto"/>
                    <label for="feature-none">Ninguna en concreto.</label>
                  </div>

                </div>

                <div class="margintop1-5">
                  <p>¿Es Parquick un servicio útil para buscar parking en tu zona de trabajo / estudio?</p>
                  
                  <div class="inputWrap">
                    <input  type="radio" id="usefulwork-yes" name="usefulwork" value="Sí" onChange={this.handleInputChange}/>
                    <label for="usefulwork-yes">Sí.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="usefulwork-no" name="usefulwork" value="No" onChange={this.handleInputChange}/>
                    <label for="usefulwork-no">No.</label>
                  </div>
                </div>

                <div class="margintop1-5">
                  <p>¿Es Parquick un servicio útil para buscar parking cuando tu destino es por ocio / recados puntuales?</p>
                  
                  <div class="inputWrap">
                    <input  type="radio" id="usefulleisure-yes" name="usefulleisure" value="Sí" onChange={this.handleInputChange}/>
                    <label for="usefulleisure-yes">Sí.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="usefulleisure-no" name="usefulleisure" value="No" onChange={this.handleInputChange}/>
                    <label for="usefulleisure-no">No.</label>
                  </div>
                  
                </div>

                {notuseful}


                <div class="margintop1-5">
                  <p>¿Pagarías 0,29€ para encontrar el parking libre más cercano y llevarte hasta él cada vez que lo necesites?</p>
                  <div class="inputWrap">
                    <input  type="radio" id="paytariff-yes" name="paytariff" value="Sí" onChange={this.handleInputChange}/>
                    <label for="paytariff-yes">Sí.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="paytariff-no" name="paytariff" value="No" onChange={this.handleInputChange}/>
                    <label for="paytariff-no">No.</label>
                  </div>
                </div>

                <div class="margintop1-5">
                  <p>¿Cuántas veces utilizarías Parquick cada mes?</p>
                  <div  class="rangevalue">{this.state.usestimes} veces</div>
                  <input type="range" min="0" max="45" name="usestimes" value={this.state.usestimes} onChange={this.handleInputChange}/>
                </div>

                <div class="margintop1-5">
                  <p>¿Tienes claro que el coste de Parquick lo compensas con el ahorro conseguido por no dar vueltas buscando, y por tanto no te cuesta dinero?</p>
                  <div class="inputWrap">
                    <input  type="radio" id="understandsaving-yes" name="understandsaving" value="Sí, lo tengo claro" onChange={this.handleInputChange}/>
                    <label for="understandsaving-yes">Sí, lo tengo claro.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="understandsaving-no" name="understandsaving" value="No, no estoy seguro" onChange={this.handleInputChange}/>
                    <label for="understandsaving-no">No, no estoy seguro.</label>
                  </div>
                </div>

                <div class="margintop1-5">
                  <p>¡Échale imaginación! Piensa que Parquick, además de encontrar los parkings libres más cercanos, te informa de la disponibilidad de otros servicios. Como conocer las mesas libres de ese restaurante al que quieres ir, pero no tienes reserva. O te recomiende otros disponibles por la zona.</p>
                  <p>¿Lo verías útil para tus hábitos?</p>
                  <div class="inputWrap">
                    <input  type="radio" id="newidea-yes" name="newidea" value="Sí" onChange={this.handleInputChange}/>
                    <label for="newidea-yes">Sí.</label>
                  </div>

                  <div class="inputWrap">
                    <input type="radio" id="newidea-no" name="newidea" value="No" onChange={this.handleInputChange}/>
                    <label for="newidea-no">No.</label>
                  </div>
                </div>

                {newideaconcept}

                <div class="margintop1-5">
                  <p>Email, para regalarte 30 días gratuitos adicionales.</p>
                  <input required type="text" name="email" value={this.state.email} onChange={this.handleInputChange} placeholder="nombre@email.com"/>
                </div>

                <div class="inputWrap margintop1">
                    <input required class="checkbox" type="checkbox" id="accept" name="accept" value="Acepto la política de privacidad"/>
                    <label for="accept">Acepto la política de privacidad.</label>
                </div>
                
                <div class="margintop2">
                  <p class="body-large">La descarga ya está lista:</p>
                </div>

                <div class="margintop1">
                  <input type="hidden" name="_next" value="https://parquick.com/descargar/gracias"/>
                  <BtnContainedPrimary>Ir a la descarga</BtnContainedPrimary>
                </div>
                
                
              </form>

            </div>
          </Grid>
        </Container>
      </Layout>
    )
  }
}